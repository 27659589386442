(($) ->
  "use strict"
  ###global Response###
  ###global console###


  MainAction =
    init: ->
      @offCanvas()
      @LightBox()

    LightBox: ->
      iLightBoxSetting =
        skin: 'mac'
        overlay:
          opacity: 0.85
        callback:
          onOpen: ->
            $('.ilightbox-overlay').css 'background-color','#000'
      $('.right-col-video-trailer-thumb-a').iLightBox(iLightBoxSetting)

      $('.right-col-video-trailer-thumb-a img').photoRatio()

    offCanvas: ->
      scotchPanel =
        $('#shenyun-off-canvas-nav').scotchPanel
          containerSelector: 'body'
          direction: 'right'
          duration: 100
          transition: 'cubic-bezier(0.42, 0, 0.58, 1)'
          # clickSelector: '#tb-navbar-toggle'
          distanceX: '70%'
          enableEscapeKey: true
          
          beforePanelOpen: ->
            $('#shenyun-off-canvas-nav').show()
          afterPanelOpen: ->
            $('.scotch-panel-wrapper').css
              overflow: 'hidden'
          
          afterPanelClose: ->
            $('.scotch-panel-wrapper').css
              overflow: 'visible'
            $('#shenyun-off-canvas-nav').fadeOut()

      $('#tb-navbar-toggle').click ->
        scotchPanel.toggle()
        return false
      $('.overlay').click ->
        scotchPanel.close()



  MainAction.init()

) jQuery